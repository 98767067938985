<template>
  <b-modal size="xl" v-model="show" title="Choose Recipient" hide-footer @hide="onHide" body-class="p-0">
    <div class="card">
      <div class="card-header justify-content-between">
        <div class="card-title">
          App User
        </div>
        <div class="flex flex-row mb-2 pr-2 float-right">
          <b-input style="width: 200px" size="sm" type="text" v-model="user_filter.search_keyword" placeholder="Input search keyword" class="mr-2"/>
          <el-select size="small" v-model="user_filter.search_by" class="mr-2" style="width: 150px">
            <el-option v-for="(item, index) in user_filter.search_options" :key="index" :value="item.value" :label="item.text"/>
          </el-select>
          <el-button type="primary" @click="searchHandler" size="small"><font-awesome-icon icon="search"/> Search</el-button>
        </div>
      </div>
      <!-- v-loading="loading.table" -->
      <div class="table-responsive">
        <el-table :data="list"
          ref="list_table"
          @selection-change="handlerSelectionChange"
          class="table card-table text-nowrap table-vcenter"
          style="width: 100%">
          <el-table-column type="selection" width="65"/>
          <el-table-column width="200" prop="full_name" label="Name"></el-table-column>
          <el-table-column min-width="300" prop="email" label="Email"></el-table-column>
          <el-table-column width="150" prop="phone_number" label="Phone Number"></el-table-column>
          <el-table-column width="150" prop="created_at_str" label="Join Date"></el-table-column>
          <el-table-column width="150" prop="login_at_str" label="Last Login Date"></el-table-column>
          <el-table-column width="120" prop="private_status" label="Account Type">
            <template slot-scope="item">
              <span class="tag" :class="item.row.private_status_color">{{ item.row.private_status }}</span>
            </template>
          </el-table-column>
          <el-table-column width="150" prop="status" label="Status">
            <template slot-scope="item">
              <span class="tag" :class="item.row.status_color">{{ item.row.status_str }}</span>
            </template>
          </el-table-column>
          <template slot="empty">
            <el-empty description="No data found" :image="emptyStateImage"></el-empty>
          </template>
        </el-table>
      </div>
      <div class="d-flex justify-content-between p-2">
        <small>Showing {{ showingFrom }} to {{ showingUntil }} of {{ total_rows }} entries</small>
        <div class="inline-table">
          <b-pagination
            class="table-cell"
            @change="pageChangeHandler"
            :per-page="per_page"
            :limit="5"
            :total-rows="total_rows"
            v-model="page"
          />
        </div>
      </div>
      <div class="d-flex justify-content-between p-2">
        <div></div>
        <div>
          <el-button @click="onSubmit" :loading="loading.submit" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
          <el-button @click="onHide" size="small" class="mr-2 ml-2">Cancel</el-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { uniqBy } from 'lodash';
import {
  GET_APPUSERS,
} from '@/store/modules/app_user';
import emptyStateImage from '@/assets/images/empty-state.png';

export default {
  name: 'ModalContentRecipient',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    currentSelected: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      emptyStateImage,
      loading: {
        table: false,
        submit: false,
      },
      formAmount: {
        amount: '',
      },
      show: false,
      page: 1,
      per_page: 10,
      total_rows: 0,
      list: [],
      isLoading: false,
      search_by: '',
      search_keyword: 'full_name',
      temp_checked: {},
      checked: {},
      user_filter: {
        search_by: 'full_name',
        search_keyword: '',
        search_options: [
          { value: 'full_name', text: 'Full Name' },
          { value: 'email', text: 'Email' },
          { value: 'phone_number', text: 'Phone Number' },
        ],
      },
      temp_selected: {},
      selected: [],
    };
  },
  methods: {
    onHide() {
      this.page = 1;
      this.list = [];
      this.selected = [];
      this.temp_selected = {};
      this.$emit('onHide');
    },
    async getList() {
      this.loading.table = true;
      await this.$store.dispatch(GET_APPUSERS, {
        page: this.page,
        per_page: this.per_page,
        search_by: this.search_by,
        search_keyword: this.search_keyword,
      }).catch(() => {});
      const { rows, count } = this.$store.getters.app_users;

      this.total_rows = count || 0;
      this.list = rows;
      this.validateIfChecked();
      this.loading.table = false;
    },
    validateIfChecked() {
      const valueToRemove = [];
      this.selected.forEach((val) => {
        const exist = this.list.find((v) => v.owned_by === val);
        if (exist) {
          valueToRemove.push(val);
          setTimeout(() => {
            console.log('toggle', exist);
            this.$refs.list_table.toggleRowSelection(exist);
          }, 100);
        }
      });
      valueToRemove.forEach((value) => {
        const index = this.selected.findIndex((val) => val === value);
        this.selected.splice(index, 1);
      });
    },
    async pageChangeHandler(page) {
      this.checked[`page${this.page}`] = this.temp_checked[`page${this.page}`] || [];

      this.page = page;
      await this.getList();
      if (this.checked[`page${this.page}`]) {
        this.checked[`page${this.page}`].forEach((v) => {
          const obj = this.list.find((k) => k.owned_by === v.owned_by || this.selected.includes(k.owned_by));
          if (obj) {
            setTimeout(() => {
              this.$refs.list_table.toggleRowSelection(obj);
            }, 100);
          }
        });
      }
    },
    handlerSelectionChange(value) {
      this.temp_selected = value[value.length - 1];
      this.$set(this.temp_checked, `page${this.page}`, value);
    },
    searchHandler() {
      this.page = 1;
      this.search_by = this.user_filter.search_by;
      this.search_keyword = this.user_filter.search_keyword;
      this.getList();
    },
    onSubmit() {
      let data = [];
      Object.keys(this.temp_checked).forEach((key) => {
        data.push(...this.temp_checked[key]);
      });
      data = uniqBy(data, 'owned_by');
      this.$emit('onSubmit', data);

      this.show = false;
    },
  },
  computed: {
    showingFrom() {
      return this.total_rows ? ((this.page - 1) * this.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.total_rows) {
        if (this.list.length < this.per_page) {
          return this.total_rows;
        }
        return this.page * this.per_page;
      }
      return 0;
    },
  },
  watch: {
    isShow() {
      this.show = this.isShow;
      if (this.show) {
        this.selected = this.currentSelected;
        this.getList();
      }
    },
  },
};
</script>