<template>
  <b-modal size="lg" v-model="is_show" :title="modalTitle" hide-footer @hide="onHide">
    <b-form @submit.prevent="onSubmit" @reset="onHide" v-loading="loading.detail">
      <b-form-group label="Section">
        <el-select v-model="v$.form.section_id.$model" class="w-full" size="medium" placeholder="Select section">
          <el-option v-for="item in parent_section" :key="item.id" :label="item.section_name" :value="item.id"></el-option>
        </el-select>
        <b-form-invalid-feedback :state="!v$.form.section_id.$invalid" v-if="v$.form.section_id.$errors[0]">
          <span> {{ v$.form.section_id.$errors[0].$message }}</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Title">
        <b-input
          v-model="v$.form.title.$model"
          placeholder="Input content title"
          type="text"/>
        <b-form-invalid-feedback :state="!v$.form.title.$invalid" v-if="v$.form.title.$errors[0]">
          <span> {{ v$.form.title.$errors[0].$message }}</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Description">
        <b-textarea
          v-model="v$.form.description.$model"
          placeholder="Input content description"/>
        <b-form-invalid-feedback :state="!v$.form.description.$invalid" v-if="v$.form.description.$errors[0]">
          <span> {{ v$.form.description.$errors[0].$message }}</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Banner">
        <b-form-file
          v-model="selected_file"
          :state="Boolean(selected_file)"
          class="mt-2 border-1 p-2"
          accept=".png, .jpg, .jpeg"
          @input="onChangeFile"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
        ></b-form-file>
        <b-form-invalid-feedback :state="!v$.form.banner_image_base64.$invalid" v-if="v$.form.banner_image_base64.$errors[0]">
          <span> {{ v$.form.banner_image_base64.$errors[0].$message }}</span>
        </b-form-invalid-feedback>
        <img class="mt-2 w-1/2" v-if="type === 'edit'" ref="current_banner_image" :src="form.banner_image"/>
      </b-form-group>
      <b-form-group label="URL">
        <b-input
          v-model="v$.form.target_url.$model"
          placeholder="Input URL to open when user click"
          type="text"/>
        <b-form-invalid-feedback :state="!v$.form.target_url.$invalid" v-if="v$.form.target_url.$errors[0]">
          <span> {{ v$.form.target_url.$errors[0].$message }}</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Apply to Specific User">
        <el-switch
          :active-value="true"
          :inactive-value="false"
          active-text="Yes"
          inactive-text="No"
          v-model="apply_to_user"
          active-color="#13ce66"
          inactive-color="#ff4949">
        </el-switch><br/><br/>
        <el-button @click="handlerBtnChooseUser" v-if="apply_to_user" size="small" type="primary">Choose User</el-button>
        <p v-if="apply_to_user" class="mt-2">Currently there is <span class="text-brand">{{ form.target_user.length }}</span> user applied for this content.</p>
      </b-form-group>
      <div class="d-flex flex-row float-right">
        <el-button @click="onSubmit" :loading="loading.submit" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
        <el-button @click="onHide" size="small" class="mr-2">Cancel</el-button>
      </div>
      <modal-content-recipient
        @onSubmit="onSubmitRecipient"
        @onHide="showModalRecipient = false"
        :is-show="showModalRecipient"
        :current-selected="form.target_user" />
    </b-form>
  </b-modal>
</template>
<script>
import useVuelidate from '@vuelidate/core';
import {
  required, url, maxLength, requiredIf,
} from '@vuelidate/validators';
import base64File from '@/library/base64File';
import {
  GET_PARENTSECTION, ADD_SECTIONCONTENT, UPDATE_SECTIONCONTENT, DETAIL_SECTIONCONTENT,
} from '@/store/modules/app_content';
import popupErrorMessages from '@/library/popup-error-messages';
import ModalContentRecipient from './ModalContentRecipient.vue';

export default {
  name: 'ModalSectionContent',
  components: {
    ModalContentRecipient,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'add',
    },
    selectedContent: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      form: {
        title: { required },
        description: { required, maxLength: maxLength(255) },
        banner_image_base64: { required: requiredIf(this.type === 'add') },
        target_url: { required, url },
        section_id: { required },
      },
    };
  },
  data() {
    return {
      is_show: false,
      showModalRecipient: false,
      loading: {
        submit: false,
        detail: false,
      },
      apply_to_user: false,
      form: {
        title: '',
        description: '',
        banner_image_base64: '',
        target_url: '',
        section_id: '',
        // new
        target_user: ''
      },
      selected_file: null,
      parent_section: [],
    };
  },
  computed: {
    modalTitle() {
      return this.type === 'add' ? 'Add New Content' : 'Update Content';
    },
  },
  methods: {
    async onSubmit() {
      this.loading.submit = true;
      if (!this.apply_to_user) {
        this.form.target_user = null;
      }
      if (this.type === 'edit') {
        await this.updateContent();
      } else {
        await this.$store.dispatch(ADD_SECTIONCONTENT, this.form)
          .then(() => {
            this.$message({
              title: 'Success',
              type: 'success',
              message: 'Content successfully created',
            });
            this.$emit('afterSubmit');
          }).catch((err) => {
            popupErrorMessages(err.response.data);
          });
      }
      this.loading.submit = false;
    },
    async updateContent() {
      await this.$store.dispatch(UPDATE_SECTIONCONTENT, {
        id: this.form.id,
        data: this.form,
      }).then(() => {
        this.$message({
          title: 'Success',
          type: 'success',
          message: 'Content successfully updated',
        });
        this.$emit('afterSubmit');
      }).catch((err) => {
        popupErrorMessages(err.response.data);
      });
    },
    onHide() {
      this.form = {
        title: '',
        description: '',
        banner_image_base64: '',
        target_url: '',
        section_id: '',
        target_user: null,
      };
      this.apply_to_user = false;
      this.$emit('onHide');
    },
    async onChangeFile() {
      this.form.banner_image_base64 = await base64File(this.selected_file);
    },
    async getParentSection() {
      await this.$store.dispatch(GET_PARENTSECTION, {
        type: 'home',
      }).then(({ rows }) => {
        this.parent_section = rows;
      });
    },
    handlerBtnChooseUser() {
      this.showModalRecipient = true;
    },
    onSubmitRecipient(data) {
      this.form.target_user = data.map((v) => v.owned_by);
      this.showModalRecipient = false;
    },
    async getDetail(id) {
      this.loading.detail = true;
      await this.$store.dispatch(DETAIL_SECTIONCONTENT, {
        id,
      }).then(({ content }) => {
        // const detail = data.content;
        this.form = {
          id: content.id,
          title: content.title,
          description: content.description,
          banner_image_base64: '',
          target_url: content.target_url,
          banner_image: content.banner_image,
          section_id: content.section_id,
          target_user: content.target_user,
        };
        if (this.form.target_user) {
          this.apply_to_user = true;
        }
      });
      this.loading.detail = false;
    },
  },
  watch: {
    show() {
      this.is_show = this.show;
      if (this.show) {
        this.v$.form.$touch();
        this.getParentSection();
        if (this.type === 'edit') {
          this.getDetail(this.selectedContent.id);
        }
      }
    },
  },
};
</script>