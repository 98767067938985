<template>
  <div class="container-xl">
    <div class="card col-lg-12">
      <div class="card-header justify-content-between">
        <div class="card-title">
          <!-- App Home Sections -->
        </div>
        <el-button @click="handlerBtnContent" type="primary" size="mini"><font-awesome-icon icon="plus"/> Add content</el-button>
      </div>
      <div class="table-responsive">
        <table class="table card-table text-nowrap table-vcenter">
          <thead>
            <tr>
              <th width="20%">Section Banner</th>
              <th width="20%">Section Name</th>
              <th width="40%">Content Title</th>
              <th width="40%">Content URL</th>
              <th width="20%">Content Description</th>
              <th>Status</th>
              <th>#</th>
            </tr>
          </thead>
          <tbody v-loading="loading.table">
            <tr v-for="item in list" :key="item.id">
              <td>
                <img :src="item.banner_image" alt="" class="card-img-top">
              </td>
              <td>{{ item.section_name }}</td>
              <td>{{ item.title }}</td>
              <td>{{ item.target_url }}</td>
              <td>{{ item.description }}</td>
              <td>
                <span class="tag" :class="item.status_color">{{ item.status_str }}</span>
              </td>
              <td>
                <button class="btn btn-secondary btn-sm dropdown-toggle" data-toggle="dropdown">Actions</button>
                  <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow p-2" style="width: 200px">
                    <a class="dropdown-item btn-sm cursor-pointer" @click="handlerBtnEdit(item)">
                      <font-awesome-icon class="text-primary" :icon="['fas', 'pen']"/>&nbsp; Edit Content
                    </a>
                    <a class="dropdown-item btn-sm cursor-pointer" @click="toggleContentStatus(item)">
                      <font-awesome-icon class="text-warning" :icon="['fas', 'power-off']"/>&nbsp; Enable/Disable Content
                    </a>
                    <a class="dropdown-item btn-sm cursor-pointer" @click="deleteContent(item)">
                      <font-awesome-icon class="text-danger" :icon="['fas', 'trash']"/>&nbsp; Remove Content
                    </a>
                  </div>
              </td>
            </tr>
          </tbody>
        </table>
        <el-empty description="No data found" v-if="total_row == 0" :image="emptyStateImage"></el-empty>
      </div>
      <div class="card-footer d-flex justify-content-between">
          <small>Showing {{ showingFrom }} to {{ showingUntil }} of {{ total_row }} entries</small>
        <div class="inline-table">
          <b-pagination
            class="table-cell"
            @change="pageChangeHandler"
            :per-page="per_page"
            :limit="5"
            :total-rows="total_row"
            v-model="page"
          />
        </div>
      </div>
      <modal-section-content
        @afterSubmit="handlerAfterSubmit"
        @onHide="isShownModalContent = false; selected_content = {}"
        :type="modalAction"
        :selected-content="selected_content"
        :show="isShownModalContent">
      </modal-section-content>
    </div>
  </div>
</template>
<script>
import { GET_HOMECONTENT, UPDATE_SECTIONCONTENT, DELETE_SECTIONCONTENT } from '@/store/modules/app_content';
import emptyStateImage from '@/assets/images/empty-state.png';
import popupErrorMessages from '@/library/popup-error-messages';
import ModalSectionContent from './ModalSectionContent.vue';

export default {
  name: 'HomeSections',
  components: {
    ModalSectionContent,
  },
  metaInfo: {
    title: 'App Content',
  },
  data() {
    return {
      emptyStateImage,
      isShownModalContent: false,
      loading: {
        modal_content: false,
        table: false,
      },
      modalAction: 'add',
      page: 1,
      per_page: 10,
      list: [],
      total_row: 0,
      selected_content: {},
    };
  },
  mounted() {
    this.getList();
  },
  computed: {
    showingFrom() {
      return this.total_row ? ((this.page - 1) * this.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.total_row) {
        if (this.list.length < this.per_page) {
          return this.total_row;
        }
        return this.page * this.per_page;
      }
      return 0;
    },
  },
  methods: {
    handlerCloseModalContent() {
      this.isShownModalContent = false;
    },
    handlerBtnContent() {
      this.isShownModalContent = true;
    },
    handlerAfterSubmit() {
      this.isShownModalContent = false;
      this.getList();
    },
    pageChangeHandler(page) {
      this.page = page;
      this.getList();
    },
    handlerBtnEdit(item) {
      this.selected_content = item;
      this.isShownModalContent = true;
      this.modalAction = 'edit';
    },
    async getList() {
      this.loading.table = true;
      await this.$store.dispatch(GET_HOMECONTENT, {
        page: this.page,
        per_page: this.per_page,
      }).catch((err) => {
        popupErrorMessages(err);
      });
      this.loading.table = false;
      const response = this.$store.getters.home_content;
      this.list = response.rows;
      this.total_row = response.count;
    },
    toggleContentStatus(item) {
      let msg = 'Are you sure want to activate this content?';
      if (item.status === 'active') {
        msg = 'Are you sure want to inactivate this content?';
      }
      this.$confirm(msg, 'Confirmation', {
        type: 'info',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            await this.$store.dispatch(UPDATE_SECTIONCONTENT, {
              id: item.id,
              data: {
                status: item.status === 'active' ? 'not_active' : 'active',
              },
            }).then(() => {
              this.$message({
                title: 'Success',
                type: 'success',
                message: 'Content successfully updated',
              });
            }).catch((err) => {
              popupErrorMessages(err.response.data);
            });
            instance.confirmButtonLoading = false;
            done();
            this.getList();
            return;
          }
          done();
        },
      }).catch(() => {});
    },
    deleteContent(item) {
      this.$confirm('Are you sure want to remove this content?', 'Confirmation', {
        type: 'info',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            await this.$store.dispatch(DELETE_SECTIONCONTENT, {
              id: item.id,
            }).then(() => {
              this.$message({
                title: 'Success',
                type: 'success',
                message: 'Content successfully removed',
              });
            }).catch((err) => {
              popupErrorMessages(err.response.data);
            });
            instance.confirmButtonLoading = false;
            done();
            this.getList();
            return;
          }
          done();
        },
      }).catch(() => {});
    },
  },
};
</script>